
.amount-panel {
  display: none;
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #F9D9E6;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  text-align: center;
  opacity: 0;

}

.amount-panel.show {
  display: flex;
  z-index: 9999; 
  animation: slideIn 1s ease-in-out forwards, fadeIn 1s ease-in-out forwards;
  opacity: 1;
}


.container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
}

.input-label {
  font-size: 18px;
  margin-bottom: 10px;
}
.input-field {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.input-field:focus{
  border: 1px solid #F9D9E6;
}
.confirm-button {
  background-color: lightgray;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
.confirm-button:hover {
  background-color: #F9D9E6;
}

.donation-page {
    display: flex;
    flex-direction: column; 
    transform: translateX(-100%);
    transition: transform 0.8s ease;
  }
  .donation-page.open {
    transform: translateX(0);
  }
  @keyframes fadeInFromLeft {
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .donation-form {
    flex: 2;
    padding: 20px;
    border: 1px solid #ccc;
    margin-right: 50px;
    margin-left: 50px;
    box-shadow: 0 2px 5px #F9D9E6;
    border-top-left-radius: 0px; /* Adjust the radius value for top-left corner */
    border-bottom-left-radius: 10px; /* Adjust the radius value for bottom-left corner */
    border-top-right-radius: 0px; /* Adjust the radius value for top-left corner */
    border-bottom-right-radius: 10px; /* Adjust the radius value for bottom-left corner */
  }
  
  .donation-info {
    flex: 1.5;
    padding: 20px;
    border: 1px solid #ccc; 
    box-shadow: 0 2px 5px #F9D9E6;
    margin-left: 50px;
    margin-right: 50px;
    background-color: #f7f7f7;
    border-top-left-radius: 10px; /* Adjust the radius value for top-left corner */
    border-bottom-left-radius: 0px; /* Adjust the radius value for bottom-left corner */
    border-top-right-radius: 10px; /* Adjust the radius value for top-left corner */
    border-bottom-right-radius: 0px; /* Adjust the radius value for bottom-left corner */
    align-items: center;
    justify-content: center;
    height: 66.67%; 
  }

  @keyframes slideIn {
    from {
      transform: translateX(-50%) translateY(100%);
    }
    to {
      transform: translateX(-50%) translateY(0);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }