.exam-papers {
    max-width: 90%;
    margin: 0 auto;
    padding: 1rem;
    background-image: linear-gradient(to right, rgba(66, 103, 178, 0.1) 10%, rgba(173, 216, 230, 0.2)); /* Adjust the RGBA values and alpha for the haze */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4); /* Create a subtle shadow */
    border-radius: 0.5rem;
    justify-content: 'center';
    align-items: center;
    height: 80vh;
    max-height: 80vh;
  }
  
  .exam-papers__title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
    font-family: "Lucida Handwriting", "Courier New", monospace;
  }
  
  .exam-papers__list {
    list-style: none;
    margin: 0;
    padding: 0;

  }
  .exam-papers__item.open {
    transform: translateX(0);
  }
  

  .exam-papers__item {
    opacity: 0;
    margin: 1rem 0;
    padding: 0.2rem;
    background-color: #FFF;
    border-radius: 0.5rem;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-color: #F9D9E6;
    width:100%;
    transform: translateX(-100%);
    transition: opacity 0.6s ease, transform 0.6s ease;
    animation: fadeInFromLeft 0.6s ease forwards;
  }
  .exam-papers__item.open {
    opacity: 1; /* Make the item visible with full opacity */
    transform: translateX(0); /* Move the item back to its original position */
  }

  @keyframes fadeInFromLeft {
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .exam-papers__metadata {
    font-size: 1rem;
    color: gray;
    
  }
  
  .exam-papers__download-link {
    display: inline-block;
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    background-color: #E5B4C2;
    color: gray;
    border-color: #F9D9E6;
    text-decoration: none;
    border-radius: 0.5rem;
    transition: background-color 0.3s ease;
  }
  
  .exam-papers__download-link:hover {
    background-color: #F9D9E6;
    border-radius: 0.5rem;
    color: gray;
  }

  .oldpapers-container {
    display: flex;
    height: 95%;
  }
  
  .left-panel {
    flex: 2;
    background-color: white;
    padding: 1.0rem 1rem;
    border-bottom-left-radius: 0.5rem;
  }
  
  .right-panel {
    flex: 6;
    background-color: #f7f7f7;
    position: relative;
    transform: translateX(-100%);
    transition: transform 0.8s ease;
    height: 100%;
    border-bottom-right-radius: 0.5rem;
  }
  
  .right-panel.open {
    transform: translateX(0);
  }
  .right-panel-content {
    padding-top: 1%;
    max-height: 100%; /* Set the fixed height you want for the panel content */
    overflow-y: auto; /* Add a vertical scroll bar when content exceeds the panel's height */
    border-bottom-right-radius: 0.5rem;
  }