.chatbot {
    max-width: 80%;
    max-height: 90%;
    margin: 0 auto;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 2px 5px #F9D9E6;
    transform: translateX(-100%);
    transition: transform 0.8s ease;
  }
  .chatbot.open {
    transform: translateX(0);
  }
  .chatbot-info {
    flex: 1.5;
    padding: 10px;
    background-color: #f7f7f7;
    border-top-left-radius: 10px; /* Adjust the radius value for top-left corner */
    border-bottom-left-radius: 0px; /* Adjust the radius value for bottom-left corner */
    border-top-right-radius: 10px; /* Adjust the radius value for top-left corner */
    border-bottom-right-radius: 0px; /* Adjust the radius value for bottom-left corner */
    align-items: center;
    justify-content: center;
    height: 20.67%; 
  }

  
  .message-container {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 10px;
    padding-right: 10px;
  }
  
  .user-message {
    background-color: #009688;
    color: #fff;
    border-radius: 5px;
    padding: 8px;
    margin: 5px 0;
    float: right;
    clear: both;
  }
  
  .bot-message {
    background-color: #f1f0f0;
    color: #333;
    border-radius: 5px;
    padding: 8px;
    margin: 5px 0;
    float: left;
    clear: both;
  }
  
  .input-container {
    display: flex;
    justify-content: space-between;
  }
  
  .chatbot-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    margin-right: 10px;
  }
  .chatbot-input:focus{
    border: 1px solid #F9D9E6;
  }

 
 .chatbot-button {
    background-color: #fff;
    border-color: #F9D9E6;
    color: darkgray;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .chatbot-button:hover {
    background-color: #F9D9E6;
  }

  @keyframes fadeInFromLeft {
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }