.section-container-left{
    display: flex;
    padding: 20px;
    width: 80%;
    height: 100%; /* Set the height to 100% of the viewport height */
    background-image: linear-gradient(to right, rgba(66, 103, 178, 0.1) 10%, rgba(173, 216, 230, 0.2)); /* Adjust the RGBA values and alpha for the haze */
    border-radius: 15px; /* Adjust the value to control the amount of rounding */
    margin-left: 10%; /* Pushes the container to the center horizontally */
    margin-right: 10%; 
    margin-top: 2%;
    margin-bottom: 1%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4); /* Create a subtle shadow */
    position: relative;
    fill-opacity: 1;
    justify-content: center;
    align-items: center;
    transform: translateX(-100%);
    transition: transform 0.8s ease;
  }
  
.section-container-right{
display: flex;
padding: 20px;
width: 80%;
height: 100%; /* Set the height to 100% of the viewport height */
background-image: linear-gradient(to right, rgba(66, 103, 178, 0.1) 10%, rgba(173, 216, 230, 0.2)); /* Adjust the RGBA values and alpha for the haze */
border-radius: 15px; /* Adjust the value to control the amount of rounding */
margin-left: 10%; /* Pushes the container to the center horizontally */
margin-right: 10%; 
margin-top: 2%;
margin-bottom: 1%;
box-shadow: 0 0 20px rgba(0, 0, 0, 0.4); /* Create a subtle shadow */
position: relative;
fill-opacity: 1;
justify-content: center;
align-items: center;
transform: translateX(200%);
transition: transform 0.8s ease;
}
.section-container-right.open {
    transform: translateX(0);
}

.section-container-left.open {
    transform: translateX(0);
}
.section-panel {
    height: fit-content;
    max-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn 1s ease-in-out forwards;
  }

  @keyframes fadeInFromLeft {
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .hero-section-left{
    flex: 6;
    display: block;
    padding: 0px;
    width: 100%;
    height: 100%; /* Set the height to 100% of the viewport height */
    border-radius: 15px; /* Adjust the value to control the amount of rounding */
    margin-left: 5%; /* Pushes the container to the center horizontally */
    margin-right: 0%; 
    margin-top: 2%;
    margin-bottom: 1%;
    position: relative;
    fill-opacity: 1;
    justify-content: center;
    align-items: center;
    transform: translateX(0);
    transition: transform 0.8s ease;
  }


  .hero-section-right{
    flex: 2;
    display: flex;
    padding: 0px;
    width: 100%;
    height: 100%; /* Set the height to 100% of the viewport height */
    border-radius: 15px; /* Adjust the value to control the amount of rounding */
    margin-left: 0%; /* Pushes the container to the center horizontally */
    margin-right: 5%; 
    margin-top: 2%;
    margin-bottom: 1%;
    position: relative;
    fill-opacity: 1;
    justify-content: center;
    align-items: center;
    transform: translateX(200%);
    transition: transform 0.8s ease;
  }
  .hero-section-right.open {
    transform: translateX(0);
}
    
  /* Add some styles to the hero section */
  .hero-section {
    background-color: #ffff;
    padding: 50px;
    text-align: center;
    margin-right: 15px;
    margin-left: 15px;
    display: flex;
    
  }
  
  .hero-section h1 {
    font-size: 5rem;
    margin-bottom: 20px;
  }
  
  .hero-section p {
    font-size: 2.0rem;
    line-height: 2rem;
    max-width: 800px;
    margin: 0 auto;
    font-family: "Lucida Handwriting", "Courier New", monospace;
  }