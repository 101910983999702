/* .user-profile {
    background-color: #f5f5f5;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 60%;
    margin: 0 auto;
    height: 60vh;
    justify-content: center;
    align-items: center;
  } */
  .user-profile {
    height: fit-content;
    max-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn 1s ease-in-out forwards;
  }
  
  .profile-details {
    margin-top: 10px;
    font-size: 16px;

  }

  
  .profile-details div {
    margin: 10px 0;
    border-bottom: 1px solid #ddd; /* Add a thin line between div elements */
    padding-bottom: 10px; /* Add some spacing between the lines */
  }
  
  strong {
    font-weight: bold;
    margin-right: 5px;
  }

  .user-profile-container{
    display: flex;
    padding: 20px;
    width: 100%;
    height: 100%; /* Set the height to 100% of the viewport height */
    background-image: linear-gradient(to right, rgba(66, 103, 178, 0.1) 10%, rgba(173, 216, 230, 0.2)); /* Adjust the RGBA values and alpha for the haze */
    border-radius: 15px; /* Adjust the value to control the amount of rounding */
    margin-left: 10%; /* Pushes the container to the center horizontally */
    margin-right: 10%; 
    margin-top: 5%;
    margin-bottom: 5%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4); /* Create a subtle shadow */
    position: relative;
    fill-opacity: 0.2;
    justify-content: center;
    align-items: center;
  }
  
  .user-profile-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    backdrop-filter: blur(20px); /* Adjust the blur for the frosted glass effect */
    border-radius: inherit;
    opacity: 0.8; /* Adjust the opacity to control the frosted glass effect */
    z-index: -1;
  }